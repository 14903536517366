exports.components = {
  "component---src-containers-about-body-about-body-js": () => import("./../../../src/containers/aboutBody/AboutBody.js" /* webpackChunkName: "component---src-containers-about-body-about-body-js" */),
  "component---src-containers-index-body-index-body-js": () => import("./../../../src/containers/indexBody/IndexBody.js" /* webpackChunkName: "component---src-containers-index-body-index-body-js" */),
  "component---src-containers-industries-body-industries-body-js": () => import("./../../../src/containers/industriesBody/IndustriesBody.js" /* webpackChunkName: "component---src-containers-industries-body-industries-body-js" */),
  "component---src-containers-what-we-do-body-what-we-do-body-js": () => import("./../../../src/containers/whatWeDoBody/WhatWeDoBody.js" /* webpackChunkName: "component---src-containers-what-we-do-body-what-we-do-body-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/WhatWeDo.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */)
}

